<template>
  <div id="report-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.reportsPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("report-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="title_pt_br" v-if="$l.isPtBr">
            {{ $l.get("report-list.table-title") }}
          </vs-th>
          <vs-th sort-key="title_es_es" v-if="$l.isEsEs">
            {{ $l.get("report-list.table-title") }}
          </vs-th>
          <vs-th sort-key="title_en_us" v-if="$l.isEnUs">
            {{ $l.get("report-list.table-title") }}
          </vs-th>
          <vs-th sort-key="permission_type">
            {{ $l.get("report-list.table-permission-type") }}
          </vs-th>
          <vs-th>{{ $l.get("report-list.table-actions") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td v-if="$l.isPtBr">{{ tr.titleEnUs }}</vs-td>
            <vs-td v-if="$l.isEnUs">{{ tr.titlePtBr }}</vs-td>
            <vs-td v-if="$l.isEsEs">{{ tr.titleEsEs }}</vs-td>

            <vs-td v-if="tr.permissionType == 1">
              {{ $l.get("dictionary.permission-types.global") }}
            </vs-td>
            <vs-td v-if="tr.permissionType == 2">
              {{ $l.get("dictionary.permission-types.plans") }}
            </vs-td>
            <vs-td v-if="tr.permissionType == 3">
              {{ $l.get("dictionary.permission-types.admins") }}
            </vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onReportEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.reportsPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCreateInteractor from "@/business/report-list/select-create";
import SelectReportEditInteractor from "@/business/report-list/select-report-edit";
import InitReportListInteractor from "@/business/report-list/init-report-list";
import ChangeFilterInteractor from "@/business/report-list/change-filter";
import ReportListScreenController from "@/adapters/controllers/screen-report-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "report-list",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        selectCreate: null,
        selectReportEdit: null,
        initReportList: null,
        changeFilter: null
      },
      data: null,
      isLoading: false,
      page: 1,
      search: "",
      sort: "",
      searchTimer: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ReportListScreenController);

    //{ INTERACTORS
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.initReportList = this.$injector.get(
      InitReportListInteractor
    );
    this.interactors.selectReportEdit = this.$injector.get(
      SelectReportEditInteractor
    );
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initReportList.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (key) {
        if (active) {
          const prefix = active == "desc" ? "-" : "";
          this.sort = `${prefix}${key}`;
        } else {
          this.sort = "";
        }
        this.interactors.changeFilter.handle(this.page, this.search, this.sort);
      }
    },
    onReportEditClick(data) {
      this.interactors.selectReportEdit.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    }
  }
};
</script>

<style lang="scss"></style>
